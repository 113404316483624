.home-video {
    margin-left: auto !important;
    margin-right: auto !important;
}

.home-video iframe {
    width: 100%; /* Make iframe responsive */
    height: auto; /* Adjust height automatically */
}

@media only screen and (min-width: 600px) {
    .home-video iframe {
        width: 880px;
        height: 495px;
    }

    .home-container > div {
        max-width: 100%;
    }

    #signup-container {
        max-width: 100%;
    }

    .affiliate { 
        max-width: 100%;
    }
}

#signup-container {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjust width to be responsive */
    padding: 4px;
}

.intro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#logo {
    max-width: 100%; /* Ensure logo is responsive */
}

@media (min-width: 900px) {
    #logo {
        max-width: 100% !important;
    }
}

#photo-grid {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

#large-image {
    width: 100%; /* Make large image responsive */
    height: auto;
}

@media (min-width: 900px) {
    #large-image {
        width: 66.6%;
    }
}

#small-image-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Make small image container responsive */
    justify-content: space-between;
}

@media (min-width: 900px) {
    #small-image-container {
        width: 33.4%;
        height: auto;
    }

    #app-preview-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        align-items: center; /* Vertically center grid items */
    }
}

.mobile-app-info {
    font-weight: bold;
}

#small-image-container img {
    width: 100%;
    height: auto;
}

#signup-container button {
    margin-bottom: 1em;
    margin-top: 1em;
    width: 150px;
}

#explore-map-link {
    margin-left: 2em;
    text-decoration: none;
    color: white;
    background-color: cadetblue;
    width: 150px;
}

/* Add a container around the main content to ensure no overflow */
.home-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px; /* Add padding to prevent text from touching the edges */
    box-sizing: border-box; /* Ensure padding is included in the total width */
}

.highlight {
    animation: highlightAnimation 2s forwards;
}

@keyframes highlightAnimation {
    from { background-color: yellow; }
    to { background-color: transparent; }
}

#overlay-container {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
}

#more-info-link {
    color: white;
    background: #007BFF;
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
}

#more-info-link:hover {
    background: #0056b3;
}

.affiliates-container {
    display: flex;
    justify-content: space-between;
}

.affiliate {
    flex: 1;
    margin: 0 10px;
    text-align: center;
}

.affiliate-logo {
    width: 250px; /* Set the desired width */
    height: 100px; /* Set the desired height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
}