.App {
  text-align: center;
}

.no-underline {
  text-decoration: none;
}

.alternate-link-color {
  color: #61dafb;
}