
#mapContainer{
    height:100%;
    width: 100%;
}

/* For watermark */
#watermark {
    position: absolute;
    font-size:12px;
    bottom: .5vh;
    right: 25vw;
    opacity: 5;
    color:black;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}
  