/*#SideBarWrapper {
    position: fixed;
    bottom: 0;
    right: 20pt;
    height:85vh; 
    width: 28%;
    background-color: white;
    border-radius: 5pt 5pt 0px 0px;
}*/

#MobileTripBuilderMessage {
    margin-top: 15%;
    height: 100%;
    width: 65%;
    background-color: #e8e8e8;
    text-align: center;
    padding:4%;
    padding-top:10%;
    padding-bottom:10%;
    border-radius: 5pt;
}

.noticeText {
    color:crimson;
    font-weight: bold;
}

.heading{
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom:10px;
}

.SideBarSearch {
    height: 100%;
    width: 100%;
    padding: 0px;
}

/* This is an extra container, so we can keep a gap between the scrollbar and the hover box */
#TrailLocationContainer{
    width:95%;
    height:100%;
}

.CurrentRidePlan {
    overflow-y: auto;
    overflow: hidden;
    height: 100%;
    width: 90%;
    padding: 0px;
    background-color: #e8e8e8;
    border-radius: 5pt;
    
}
#emptyTripMessage {
    align-self: center;
    color: #888888;
    width:100%;
    text-align: center;
}

#SearchFilter {
    width: 100%;
    height:80%;
    margin-top:10px;
    margin-bottom: 3%;
}

#SearchFilter:hover {
    border-radius: 5pt;
    background-color: rgba(211, 211, 211, 0.298);
    cursor: pointer;
}

.sidebar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}

#titleArea {
    height: 5%;
    width: 100%;
}

#searchSystem {
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y:hidden;
}

#POIBox{
    border-style: solid;
    border-radius: 5pt;
    height: 60%;
}

#filterButtonArea {
    width: 95%;
}
#tripArea { 
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:100%;
    flex-grow: 1;
}

#POIBox{
    border-style: solid;
    border-radius: 5pt;
    max-height: 100px;
}

.tripInfoArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.tripInfoText {
    font-size: .75em;
}

.sled_button { 
    border-radius: 5pt;
    border: none;
    color: white;
    background-color: #2A992A;
    width: 200px;
    height: 40px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.sled_button:hover {
    filter: brightness(115%);
}

#arrowButton {
    position:absolute;
    top:10px;
    left:10px;
    height: 12vw;
    width: 12%;
    border-radius: 5pt ;
    background-color: #f0f0f0;
    border-bottom: 1px solid #666666;
    align-content: center;
}

#arrowButtonIcon{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%;
    color:#666666;
}

#arrowButtonIconHide{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    transform: rotate(180deg);
    height: 50%;
    width: 50%;
    
}

#trip-type-selector {
    height: 50px;
    width: 150px;
}