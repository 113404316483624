#header-image {
    background-color: #676767;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 2px;
    overflow: hidden;
    height: 100vh;
    position: relative;
}

@media (max-width: 600px) {
    #header-image {
        background-size: contain;
        height: 50vh;
    }
}