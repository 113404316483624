@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

#LogoImage{
    height:5vh;
    margin-left:1.5vh;
}

#HeaderContainer {
    height:10vh;
    border-radius: 0 0 5pt 5pt;
    background-color: white;
}

#NavAvatar {
    float:right;
    margin-right:1.5vh;
}

#profilePopUp p,#emailTextfield,#emailSubmitButton {
    margin:10px;
}

#emailSubmitButton {
    background-color: crimson;
}

.NavLink:hover {
    cursor: pointer;
    border-bottom:solid 2px;
    padding: 5px 10px;
    border-color: black;
}

.emailText {
    width:80%;
}

#AlphaTag {
    color:crimson;
    font-size: x-small;
    margin-top:-7%;
    font-weight: bolder;
}