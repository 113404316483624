.page-content a {
    color: #DC143C;
}

.tos {
    margin-left: 1.5em;
    margin-right: 1.5em;
}

.strong {
    font-weight: bold;
}