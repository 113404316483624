.popup {
    display: flex;
    flex-direction: column;
}

.popup_name {
    font-size: 2em;
    padding-bottom: .5em;
}
.info_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.popup_button { 
    border-radius: 30px;
    border: none;
    color: white;
    flex: 1;
}
.popup_button:hover {
    filter: brightness(115%);
}
.full_width_popup_button {
    width: 100%;
}
.in_trip {
    background-color: #B43728 !important;
}
.not_in_trip {
    background-color: #32CD32 !important;
}

a.popup-club-link, a.popup-club-link {
    color: #B43728;
    text-decoration-color: #B43728;
}

a.popup-club-link:hover {
    filter: brightness(115%);
}

a.popup-club-link:active {
    filter: brightness(85%);
}

.mapboxgl-popup-close-button {
    font-size: 24px;
    width: 48px;
    height: 48px;
}

.location-popup-contact-info {
    margin-bottom: 1em;
}