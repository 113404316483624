.pageContainer {
    height: 90vh;
    width: 100%;
}

.pageContainerMobile {
    height: 90vh;
    width: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

#MapMobile {
    height: 100%;
    width: 100%;
    transition: height 0.3s ease-out;
}

#MapMobileExtend {
    height: 93%;
    width: 100%;
    transition: height 0.3s ease-out;
}

#TripSideBarMobileHide {
    height: 10%;
    overflow: hidden;
    overflow-y: auto;
    transition: height 0.3s ease-out;
    width: 100%;
    position:fixed;
    bottom:0;
    background-color: white;
    border-radius: 5pt 5pt 0px 0px;
}

#TripSideBarMobile {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height:50%;
    transition: height 0.3s ease-out;
    position:fixed;
    bottom:0;
    background-color: white;
    border-radius: 5pt 5pt 0px 0px;
}

.tripBuilder {
    width: 40%;
    min-width: 300px;
    /* max-width: 400px; */
}